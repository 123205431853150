<template>
  <div>
    <div :class="classReverseItems" class="d-flex">
      <div>
        <button
          :class="[reverse ? 'ml-3' : 'mr-3']"
          type="button"
          class="d-flex m-0 p-0 border-0 bg-transparent"
          :id="`popover-target-${message.id}`"
        >
          <img class="avatar" :src="message.sender.cover_image_url" />
        </button>
      </div>
      <div class="d-flex flex-column">
        <span class="font-weight-bold"
          >{{ message.sender.first_name }} {{ message.sender.last_name ? message.sender.last_name : '' }}</span
        >
        <p class="mt-1 pt-2 pb-1 px-2 rounded-lg" :style="dynamicStyle" v-html="message.text"></p>
      </div>
      <b-popover
        :target="`popover-target-${message.id}`"
        :triggers="`${!reverse && 'hover'}`"
        :placement="`${!reverse && 'leftbottom'}`"
      >
        <b-img-lazy fluid :src="message.sender.cover_image_url" />
        <div class="p-3">
          <h4 class="my-2 font-weight-bold">{{ message.sender.first_name }}</h4>
          <hs-button
            class="mt-3"
            variant="outline-primary"
            block
            :to="{ name: 'MemberDetail', params: { id: message.sender.id } }"
          >
            {{ $t('messages.views.subject.components.message-item.show-profile') }}
          </hs-button>
        </div>
      </b-popover>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessageItem',
  props: {
    message: {
      type: Object,
      required: true,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classReverseItems() {
      return {
        'flex-row-reverse text-right': this.reverse,
      };
    },
    dynamicStyle() {
      return {
        background: this.reverse ? '#CCF9FB' : '#F4F4F4',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .popover-body {
  padding: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
